import "./src/styles/global.css"
import "prismjs/themes/prism-tomorrow.css";

import "fontsource-lexend-deca"
import "fontsource-ibm-plex-sans"
import "fontsource-source-code-pro"

// Sets the initial html class attribute to correct theme, i.e. dark or light
const prefersDarkScheme =
  typeof window !== "undefined" && window.matchMedia
    ? window.matchMedia("(prefers-color-scheme: dark)")
    : ""
if (
  typeof document !== "undefined" &&
  document?.querySelector("html").classList.length === 0
) {
  document
    .querySelector("html")
    .classList.add(prefersDarkScheme.matches ? "dark" : "light")
}
